import {
  modalitiesRTB,
  modalitiesCatA,
  modalitiesCatB,
  modalitiesCatC,
} from "constants/modalities";
import { pricing } from "constants/pricing";

const handlePricing = (values) => {
  let totals = {
    total: {
      premium: 0,
      optional: 0,
    },

    tax: 1,
  };
  let price = 0;

  // determine if the pricing is for new business or old, and what the effective date is
  let effectiveDate = new Date().setHours(24, 0, 0, 0); // next midnight
  if (values.applicantInformation.newPolicyyes_no === "yes" && values.applicantInformation.expiring_policydifferentDate !== "") {
    effectiveDate = new Date(values.applicantInformation.expiring_policydifferentDate).setHours(24, 0, 0, 0);
  }
  let thisPricing;

  // knowing the effective date, go backwards through the pricing array to find the pricing that matches
  // pricing in array is epoch time, so multiply by 1000 to get javascript version
  for (let i = pricing.length - 1; i >= 0; i--) {
    if (values.applicantInformation.newPolicyyes_no === "yes") {
      if (effectiveDate > pricing[i].effective.new * 1000) {
        thisPricing = pricing[i];
        break;
      }
    } else {
      if (effectiveDate > pricing[i].effective.renewal * 1000) {
        thisPricing = pricing[i];
        break;
      }
    }
  }

  /*
  // --- Nutritional Consultant
  if (
    (values.NC.CANNP === "yes" || values.NC.CSNNAA === "yes") &&
    values.applicantInformation.modalities.indexOf("NC") >= 0
  ) {
    let premium = thisPricing.ncPrices.premium,
      optional = 0;

    thisPricing.personalTrainerPrices.standard = 0;
    thisPricing.personalTrainerPrices.extended = 0;
    thisPricing.catCPrices["Crossfit trainer"] = 0;

    if (values.NC.CANNP === "yes") {
      premium = thisPricing.ncPrices.CANNP;
      thisPricing.ncPrices.cooking = 50;
    }

    if (values.NC.CSNNAA === "yes") {
      premium = thisPricing.ncPrices.CSNNAA;
      thisPricing.ncPrices.cooking = 50;
    }

    if (values.personalTrainer.selectedOption === "standard") {
      premium += 40;
    }

    if (
      // values.applicantInformation.modalities.indexOf("NC") >= 0 ||
      // values.applicantInformation.modalities.indexOf("Fitness Instructor") >=
      //   0 ||
      values.personalTrainer.selectedOption === "extended" ||
      values.applicantInformation.modalities.indexOf("Crossfit trainer") >= 0
    ) {
      premium += 60;
    }

    // add-on for all modalities: Privacy Breach/Cyber Security coverage
    if (values.privacyBreach.yesno === "yes") {
      premium += thisPricing.privacyBreach.premium;
    }

    totals.total.premium = premium;
    totals.total.optional = handleOptions(values);
    totals.tax = handleTax(values);

    return totals;
  }

  // --- Reflexology
  if (
    values.reflexology.RAC === "yes" &&
    values.applicantInformation.modalities.indexOf("Reflexology") >= 0
  ) {
    let premium = thisPricing.reflexologyPrices.premium,
      optional = 0;

    // handle associations
    if (values.reflexology.RAC === "yes") {
      premium = thisPricing.reflexologyPrices.RACPrices[values.reflexology.RACPolicy] || premium;
    }
    
    totals.reflexology.premium = premium;
    totals.reflexology.optional = optional;

    if (values.reflexology.RACPolicy === "RACOption1") {
      if (
        values.applicantInformation.modalities.indexOf("Yoga") >= 0 ||
        values.applicantInformation.modalities.indexOf("Esthetics") >= 0 ||
        values.applicantInformation.modalities.indexOf("Pilates") >= 0 ||
        values.applicantInformation.modalities.indexOf("Aromatherapy") >= 0
      ) {
        premium += 30;
      }

      if (
        values.applicantInformation.modalities.indexOf("NC") >= 0 ||
        values.applicantInformation.modalities.indexOf("Personal Trainer") >=
          0 ||
        values.applicantInformation.modalities.indexOf("Fitness Instructor") >=
          0
      ) {
        premium += 60;
      }
    }

    if (values.reflexology.RACPolicy === "RACOption2") {
      if (
        values.applicantInformation.modalities.indexOf("Yoga") >= 0 ||
        values.applicantInformation.modalities.indexOf("Esthetics") >= 0 ||
        values.applicantInformation.modalities.indexOf("Pilates") >= 0 ||
        values.applicantInformation.modalities.indexOf("Aromatherapy") >= 0
      ) {
        premium += 10;
      }

      if (
        values.applicantInformation.modalities.indexOf("NC") >= 0 ||
        values.applicantInformation.modalities.indexOf("Personal Trainer") >=
          0 ||
        values.applicantInformation.modalities.indexOf("Fitness Instructor") >=
          0
      ) {
        premium += 45;
      }
    }

    // add-on for all modalities: Privacy Breach/Cyber Security coverage
    if (values.privacyBreach.yesno === "yes") {
      premium += thisPricing.privacyBreach.premium;
    }

    totals.total.premium = premium;
    totals.total.optional = handleOptions(values);
    totals.tax = handleTax(values);

    return totals;
  }

  // --- Yoga
  if (
    values.applicantInformation.modalities.indexOf("Yoga") >= 0 &&
    (values.yoga.modo === "yes" ||
      values.yoga.yyoga === "yes" ||
      values.yoga.power === "yes")
  ) {
    let premium =
        values.yoga.modo === "yes" ||
        values.yoga.yyoga === "yes" ||
        values.yoga.power === "yes"
        ? thisPricing.yogaPrices.listedStudio
        : thisPricing.yogaPrices.premium,
      optional = 0;
    if (
      values.yoga.modo === "yes" ||
      values.yoga.yyoga === "yes" ||
      values.yoga.power === "yes"
    ) {
      thisPricing.ncPrices.premium = 0;
      thisPricing.ncPrices.CANNP = 0;
      thisPricing.ncPrices.CSNNAA = 0;
      thisPricing.fitnessInstructorPrices.premium = 0;

      thisPricing.personalTrainerPrices.standard = 0;
      thisPricing.personalTrainerPrices.extended = 0;
      thisPricing.catCPrices["Crossfit trainer"] = 0;

      if (
        values.applicantInformation.modalities.indexOf("NC") >= 0 ||
        values.applicantInformation.modalities.indexOf("Fitness Instructor") >=
          0 ||
        values.personalTrainer.selectedOption === "standard"
      ) {
        premium += 40;
      }

      if (
        // values.applicantInformation.modalities.indexOf("NC") >= 0 ||
        // values.applicantInformation.modalities.indexOf("Fitness Instructor") >=
        //   0 ||
        values.personalTrainer.selectedOption === "extended" ||
        values.applicantInformation.modalities.indexOf("Crossfit trainer") >= 0
      ) {
        premium += 60;
      }
    }

    if (values.yoga.paddleboardaddCoverage === "yes") {
      optional += thisPricing.yogaPrices.paddleboard;
    }

    if (values.yoga.therapyaddCoverage === "yes") {
      optional += thisPricing.yogaPrices.therapy;
    }
    if (values.yoga.ebookaddCoverage === "yes") {
      optional += thisPricing.yogaPrices.ebook;
    }
    if (
      values.yoga.consultingaddCoverage === "yes" &&
      !thisPricing.optionalCoveragesCharged["consulting"]
    ) {
      optional += thisPricing.yogaPrices.consulting;
      thisPricing.optionalCoveragesCharged["consulting"] = true;
    }
    if (values.yoga.suspension === "yes") {
      premium = 0;
      optional = 0;
    }

    totals.total.premium = premium;
    totals.total.optional = handleOptions(values);
    totals.tax = handleTax(values);
    return totals;
  }
  */

  totals.total.premium = handlePremiums(values);
  totals.total.optional = handleOptions(values);
  totals.tax = handleTax(values);

  return totals;
};


/** - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * 
 * handlePremiums
 * 
 */
const handlePremiums = (values) => {
  let totals = {
    RMT: {
      premium: 0,
      optional: 0,
    },
    yoga: {
      premium: 0,
      optional: 0,
    },
    aromatherapy: {
      premium: 0,
      optional: 0,
    },
    NC: {
      premium: 0,
      optional: 0,
    },
    pilates: {
      premium: 0,
      optional: 0,
    },
    psychologist: {
      premium: 0,
      optional: 0,
    },
    personalTrainer: {
      premium: 0,
      optional: 0,
    },
    reflexology: {
      premium: 0,
      optional: 0,
    },
    osteopathy: {
      premium: 0,
      optional: 0,
    },
    kinesiology: {
      premium: 0,
      optional: 0,
    },
    physiotherapy: {
      premium: 0,
      optional: 0,
    },
    fitnessInstructor: {
      premium: 0,
      optional: 0,
    },
    catA: {
      premium: 0,
      optional: 0,
    },
    catB: {
      premium: 0,
      optional: 0,
    },
    catC: {
      premium: 0,
      optional: 0,
    },
    acupuncture: {
      premium: 0,
      optional: 0,
    },
    esthetics: {
      premium: 0,
      optional: 0,
    },
    lifeCoach:{
        premium:0,
        optional:0
    },
    sleepConsultant:{
        premium:0,
        optional:0
    },
    reiki:{
        premium:0,
        optional:0
    },
    herbalist:{
      premium: 0,
      optional: 0
    },
    registeredDietitian:{
      premium: 0,
      optional: 0
    },
    privacyBreach: {
      premium: 0,
      optional: 0
    },
  };
  let price = 0;

  // determine if the pricing is for new business or old, and what the effective date is
  let effectiveDate = new Date().setHours(24, 0, 0, 0); // next midnight
  if (values.applicantInformation.newPolicyyes_no === "yes" && values.applicantInformation.expiring_policydifferentDate !== "") {
    effectiveDate = new Date(values.applicantInformation.expiring_policydifferentDate).setHours(24, 0, 0, 0);
  }
  let thisPricing;

  // knowing the effective date, go backwards through the pricing array to find the pricing that matches
  // pricing in array is epoch time, so multiply by 1000 to get javascript version
  for (let i = pricing.length - 1; i >= 0; i--) {
    if (values.applicantInformation.newPolicyyes_no === "yes") {
      if (effectiveDate > pricing[i].effective.new * 1000) {
        thisPricing = pricing[i];
        break;
      }
    } else {
      if (effectiveDate > pricing[i].effective.renewal * 1000) {
        thisPricing = pricing[i];
        break;
      }
    }
  }

  // RMT (Ontario)
  if (
    values.applicantInformation.modalities.indexOf("RMT") >= 0 &&
    values.RMT.goodStanding !== "no" &&
    values.applicantInformation.province === "Ontario"
  ) {
    let premium = thisPricing.vals[values.RMT.selection] || 0,
      optional = 0;
    if (
      values.RMT.extraModalities === "yes" &&
      (values.RMT.selection.indexOf("annualRMT") >= 0 ||
        values.RMT.selection.indexOf("newGradRMT") >= 0)
    ) {
      optional += 100;
    } else if (
      values.RMT.extraModalities === "yes" &&
      values.RMT.selection.indexOf("sixMonth") >= 0 
    ) {
      optional += 50;
    }

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    if (values.RMT.goodStandingapplied === "no") {
      totals.RMT.premium = 0;
      totals.RMT.optional = 0;
    } else {
      totals.RMT.premium = premium;
      totals.RMT.optional = optional;
    }
    thisPricing.yogaPrices.premium = 0;
    thisPricing.aromatherapyPrices.premium = 0;
    thisPricing.aromatherapyPrices.CFA = 0;
    thisPricing.aromatherapyPrices.BCAPA = 0;
    thisPricing.ncPrices.premium = 0;
    thisPricing.personalTrainerPrices.standard = 0;
    thisPricing.personalTrainerPrices.extended = 0;
    thisPricing.reflexologyPrices.premium = 0;
    thisPricing.osteopathyPrices.fullPremium = 0;
    thisPricing.osteopathyPrices.studentPremium = 0;
    thisPricing.kinesiologyPrices.premium = 0;
    thisPricing.physiotherapyPrices.premium = 0;
    thisPricing.fitnessInstructorPrices.premium = 0;
  }

  // RMT (British Columbia)
  if (
    values.applicantInformation.modalities.indexOf("RMT") >= 0 &&
    values.RMT.goodStanding !== "no" &&
    values.applicantInformation.province === "British Columbia"
  ) {
    let premium = thisPricing.rmtBC[values.RMT.selection] || 0,
      optional = 0;
    if (
      values.RMT.extraModalities === "yes" &&
      values.RMT.selection.indexOf("annual") >= 0
    ) {
      optional += 100;
    }

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    if (values.RMT.goodStandingapplied === "no") {
      totals.RMT.premium = 0;
      totals.RMT.optional = 0;
    } else {
      totals.RMT.premium = premium;
      totals.RMT.optional = optional;
    }
    thisPricing.yogaPrices.premium = 0;
    thisPricing.aromatherapyPrices.premium = 0;
    thisPricing.aromatherapyPrices.CFA = 0;
    thisPricing.aromatherapyPrices.BCAPA = 0;
    thisPricing.ncPrices.premium = 0;
    thisPricing.personalTrainerPrices.standard = 0;
    thisPricing.personalTrainerPrices.extended = 0;
    thisPricing.reflexologyPrices.premium = 0;
    thisPricing.osteopathyPrices.fullPremium = 0;
    thisPricing.osteopathyPrices.studentPremium = 0;
    thisPricing.osteopathyPrices.NMOS = 0;
    thisPricing.kinesiologyPrices.premium = 0;
    thisPricing.physiotherapyPrices.premium = 0;
    thisPricing.fitnessInstructorPrices.premium = 0;
  }

  // RMT Saskatchewan
  if (
    values.applicantInformation.modalities.indexOf("RMT") >= 0 &&
    values.applicantInformation.province === "Saskatchewan"
  ){
    let premium = thisPricing.rmtSK[values.RMT.selection] || 0,
      optional = 0;
      if(values.RMT.practiceOsteopathyyesNo === "yes"){
        optional += 300;
      }
    totals.RMT.premium = premium;
    totals.RMT.optional = optional;
  }
  // Physiotherapy
  if (values.applicantInformation.modalities.indexOf("Physiotherapy") >= 0) {
    let premium = 0,
      optional = 0;
    premium = thisPricing.physiotherapyPrices[values.physiotherapy.selection] || 0;

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    totals.physiotherapy.premium = premium;
    totals.physiotherapy.optional = optional;

    thisPricing.yogaPrices.premium = 0;
    thisPricing.aromatherapyPrices.premium = 0;
    thisPricing.aromatherapyPrices.CFA = 0;
    thisPricing.aromatherapyPrices.BCAPA = 0;
    thisPricing.ncPrices.premium = 0;
    thisPricing.personalTrainerPrices.standard = 0;
    thisPricing.personalTrainerPrices.extended = 0;
    thisPricing.reflexologyPrices.premium = 0;
    thisPricing.osteopathyPrices.fullPremium = 0;
    thisPricing.osteopathyPrices.studentPremium = 0;
    thisPricing.kinesiologyPrices.premium = 0;
    thisPricing.fitnessInstructorPrices.premium = 0;
  }

  // Yoga
  if (values.applicantInformation.modalities.indexOf("Yoga") >= 0) {
    let premium =
        values.yoga.modo === "yes" ||
        values.yoga.yyoga === "yes" ||
        values.yoga.power === "yes"
        ? thisPricing.yogaPrices.listedStudio
        : thisPricing.yogaPrices.premium,
      optional = 0;
    if (
      values.yoga.modo === "yes" ||
      values.yoga.yyoga === "yes" ||
      values.yoga.power === "yes"
    ) {
      thisPricing.ncPrices.premium = 0;
      thisPricing.ncPrices.CANNP = 0;
      thisPricing.ncPrices.CSNNAA = 0;
      //thisPricing.fitnessInstructorPrices.premium = 0; since we need to consider the one with higher premoum IMS-1885

      thisPricing.personalTrainerPrices.standard = 0;
      thisPricing.personalTrainerPrices.extended = 0;
      thisPricing.catCPrices["Crossfit trainer"] = 0;

      if (
        values.applicantInformation.modalities.indexOf("NC") >= 0 ||
        values.applicantInformation.modalities.indexOf("Fitness Instructor") >=
          0 ||
        values.personalTrainer.selectedOption === "standard"
      ) {
        premium += 40;
        //find the modality with a higher premium
        thisPricing.fitnessInstructorPrices.premium > premium ?
            premium=thisPricing.fitnessInstructorPrices.premium :
            premium=premium
      }

      if (
        // values.applicantInformation.modalities.indexOf("NC") >= 0 ||
        // values.applicantInformation.modalities.indexOf("Fitness Instructor") >=
        //   0 ||
        values.personalTrainer.selectedOption === "extended" ||
        values.applicantInformation.modalities.indexOf("Crossfit trainer") >= 0
      ) {
        premium += 60;
      }
    }

    if (values.yoga.paddleboardaddCoverage === "yes") {
      optional += thisPricing.yogaPrices.paddleboard;
    }

    if (values.yoga.therapyaddCoverage === "yes") {
      optional += thisPricing.yogaPrices.therapy;
    }
    if (values.yoga.ebookaddCoverage === "yes") {
      optional += thisPricing.yogaPrices.ebook;
    }
    if (values.yoga.consultingaddCoverage === "yes") {
      optional += thisPricing.yogaPrices.consulting;
      thisPricing.optionalCoveragesCharged["consulting"] = true;
    }

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    if (values.yoga.suspension === "yes") {
      totals.yoga.premium = 0;
      totals.yoga.optional = 0;
    } else {
      totals.yoga.premium = premium;
      totals.yoga.optional = optional;
    }
  }

  // Aromatherapy
  if (values.applicantInformation.modalities.indexOf("Aromatherapy") >= 0) {
    let premium = thisPricing.aromatherapyPrices.premium,
      optional = 0;

    if (values.aromatherapy.CFA === "yes") {
      premium = thisPricing.aromatherapyPrices.CFA;
    }

    if (values.aromatherapy.BCAPA === "yes") {
      premium = thisPricing.aromatherapyPrices.BCAPA;
    }

    if (values.aromatherapy.consultingaddCoverage === "yes") {
      optional += thisPricing.aromatherapyPrices.consulting;
      thisPricing.optionalCoveragesCharged["consulting"] = true;
    }

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    totals.aromatherapy.premium = premium;
    totals.aromatherapy.optional = optional;
  }

  // Nutritionist
  if (values.applicantInformation.modalities.indexOf("NC") >= 0) {
    let premium = thisPricing.ncPrices.premium,
      optional = 0;
    // Charge them 0 for BIE if they practice NC. They get charged only if the want it as an optional coverage.
    // thisPricing.catCPrices["Bioenergetics Intolerance Elimination"] = 0;

    /* Association pricing */
    if (values.NC.CANNP === "yes") {
      premium = thisPricing.ncPrices.CANNP;
    } else if (values.NC.CSNNAA === "yes") {
      premium = thisPricing.ncPrices.CSNNAA;
    }

    /* Compute optional coverage */
    if (values.NC.canceraddCoverage === "yes") {
      optional += thisPricing.ncPrices.cancer;
    }
    if (values.NC.bioenergeticsaddCoverage === "yes") {
      thisPricing.catCPrices["Bioenergetics Intolerance Elimination"] = 0;
      optional += thisPricing.ncPrices.bioenergetics;
    }
    if (values.NC.cookingaddCoverage === "yes") {
      optional += thisPricing.ncPrices.cooking;
    }
    if (values.NC.consultingaddCoverage === "yes") {
      optional += thisPricing.ncPrices.webconsulting;
      thisPricing.optionalCoveragesCharged["consulting"] = true;
    }
    if (values.NC.microscopyaddCoverage === "yes") {
      optional += thisPricing.ncPrices.microscopy;
    }
    if (values.NC.ebookaddCoverage === "yes") {
      optional += thisPricing.ncPrices.ebook;
    }
    if(values.NC.fitnessInstructorAddCoverage === "yes"){
      optional += thisPricing.ncPrices.fitnessInstructorCoverage;  
    }
    if(values.NC.personalTrainerAddCoverage === "yes"){
      optional += values.NC.personalTrainerAddCoverageOption === "extended"?
        thisPricing.ncPrices.personalTrainerCoverageExtended : thisPricing.ncPrices.personalTrainerCoverageStandard;
    }

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    totals.NC.premium = premium;
    totals.NC.optional = optional;
  }

  // Personal Trainer
  if (values.applicantInformation.modalities.indexOf("Personal Trainer") >= 0) {
    let premium = 0,
      optional = 0;
    /* Compute premium */
    if (values.personalTrainer.selectedOption === "standard") {
      premium += thisPricing.personalTrainerPrices.standard;
    }
    if (values.personalTrainer.selectedOption === "extended") {
      premium += thisPricing.personalTrainerPrices.extended;
    }

    /* Compute optional coverage */
    if (values.personalTrainer.consultingaddCoverage === "yes") {
      optional += thisPricing.personalTrainerPrices.webconsulting;
      thisPricing.optionalCoveragesCharged["consulting"] = true;
    }

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    totals.personalTrainer.premium = premium;
    totals.personalTrainer.optional = optional;
  }

  // Reflexology
  if (values.applicantInformation.modalities.indexOf("Reflexology") >= 0) {
    let premium = thisPricing.reflexologyPrices.premium,
      optional = 0;

    /* handle associations */
    if (values.reflexology.RAC === "yes") {
      premium = thisPricing.reflexologyPrices.RACPrices[values.reflexology.RACPolicy] || 0;
    } else {
      totals.reflexology.premium = premium;
      totals.reflexology.optional = optional;
    }

    if (values.reflexology.certificationaddCoverage === "yes") {
      optional += thisPricing.reflexologyPrices.certification;
      thisPricing.optionalCoveragesCharged["certification"] = true;
    }

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    totals.reflexology.premium = premium;
    totals.reflexology.optional = optional;
  }

  // Osteopathy
  if (values.applicantInformation.modalities.indexOf("Osteopathy") >= 0) {
    let premium = thisPricing.osteopathyPrices.fullPremium,
      optional = 0;

    // Removed as of June 2023
    // if (values.osteopathy.associationPicker === "NMOS") {
    //   premium = thisPricing.osteopathyPrices.NMOS;
    // } else if (values.osteopathy.associationPicker !== "NMOS" && values.osteopathy.associationPicker !== "") {
    //   premium = thisPricing.osteopathyPrices.Others;
    // }
    if (
      values.osteopathy.student === "yes" &&
      values.osteopathy.completedStudentyesNo === "no"
    ) {
      premium = thisPricing.osteopathyPrices.studentPremium;
    }

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    totals.osteopathy.premium = premium;
    totals.osteopathy.optional = optional;
  }

  // Kinesiology
  if (values.applicantInformation.modalities.indexOf("Kinesiology") >= 0) {
    let premium = thisPricing.kinesiologyPrices.premium,
      optional = 0;

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    totals.kinesiology.premium = premium;
    totals.kinesiology.optional = optional;
  }

  // Fitness Instructor
  if (
    values.applicantInformation.modalities.indexOf("Fitness Instructor") >= 0
  ) {
    let premium = thisPricing.fitnessInstructorPrices.premium,
      optional = 0;

    if (values.fitnessInstructor.paddleboardaddCoverage === "yes") {
      optional += thisPricing.fitnessInstructorPrices.paddleboard;
    }
    if (values.fitnessInstructor.consultingaddCoverage === "yes") {
      optional += thisPricing.fitnessInstructorPrices.webconsulting;
      thisPricing.optionalCoveragesCharged["consulting"] = true;
    }

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    totals.fitnessInstructor.premium = premium;
    totals.fitnessInstructor.optional = optional;
  }

  // Pilates
  if (values.applicantInformation.modalities.indexOf("Pilates") >= 0) {
    let premium = thisPricing.pilatesPrices.premium,
      optional = 0;

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    totals.pilates.premium = premium;
    totals.pilates.optional = optional;
  }

  if (
    modalitiesCatA.some((item) =>
      values.applicantInformation.modalities.includes(item)
    )
  ) {
    totals.catA.premium = thisPricing.catAPrices.premium;
    //totals.catA.optional = (values.privacyBreach.yesno === "yes") ? thisPricing.privacyBreach.premium : 0;
  }

  if (
    modalitiesCatB.some((item) =>
      values.applicantInformation.modalities.includes(item)
    )
  ) {
    totals.catB.premium = thisPricing.catBPrices.premium;
    //totals.catB.optional = (values.privacyBreach.yesno === "yes") ? thisPricing.privacyBreach.premium : 0;
  }

  if (
    modalitiesCatC.some((item) =>
      values.applicantInformation.modalities.includes(item)
    )
  ) {
    values.applicantInformation.modalities.forEach((item) => {
      if (modalitiesCatC.indexOf(item) >= 0) {
        totals.catC.premium = Math.max(totals.catC.premium, thisPricing.catCPrices[item]);
      }
    });
    //totals.catC.optional = (values.privacyBreach.yesno === "yes") ? thisPricing.privacyBreach.premium : 0;
  }

  // Psychologist
  if (values.applicantInformation.modalities.indexOf("Psychologist") >= 0) {
    let premium = thisPricing.psychologistPrices[values.psychologist.selectedOption] || 0,
      optional = 0;
    if (values.psychologist.gcl === "yes") {
      optional = thisPricing.psychologistPrices.optionalPremium;
    }

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    totals.psychologist.premium = premium;
    totals.psychologist.optional = optional;
  }

  // Acupuncture
  if (values.applicantInformation.modalities.indexOf("Acupuncture/TCM") >= 0) {
    let premium = 0, optional = 0;
    if (values.acupuncture.associationMember === "yes") {
      premium = thisPricing.acupuncturePrices.association[values.acupuncture.selectedOption] || 0;
    } else if (values.applicantInformation.province === "Ontario") {
      premium = thisPricing.acupuncturePrices.ontario[values.acupuncture.selectedOption] || 0;
    } else {
      premium = thisPricing.acupuncturePrices.other[values.acupuncture.selectedOption] || 0;
    }

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    totals.acupuncture.premium = premium;
    totals.acupuncture.optional = optional;
  }

  // Esthetics
  if (values.applicantInformation.modalities.indexOf("Esthetics") >= 0) {
    let premium = thisPricing.estheticsPrices.premium,
      optional = 0;

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    totals.esthetics.premium = premium;
    totals.esthetics.optional = optional;
  }
  if (values.applicantInformation.modalities.indexOf("Life Coach") >= 0) {
    let premium = thisPricing.lifeCoach.premium,
      optional = 0;

      if (values.lifeCoach.consultingCharge === "yes") {
        optional += thisPricing.lifeCoach.consulting;
        thisPricing.optionalCoveragesCharged["consulting"] = true;
      }
  
      //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;
      totals.lifeCoach.premium = premium;
      totals.lifeCoach.optional= optional;
  }
  if (values.applicantInformation.modalities.indexOf("Sleep Consultant") >= 0) {
    let premium = thisPricing.sleepConsultant.premium,
      optional = 0;

      if (values.sleepConsultant.consultingCharge === "yes") {
        optional += thisPricing.sleepConsultant.consulting;
        thisPricing.optionalCoveragesCharged["consulting"] = true;
      }
  
      //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;
      totals.sleepConsultant.premium = premium;
      totals.sleepConsultant.optional= optional;
  }
  if (values.applicantInformation.modalities.indexOf("Reiki") >= 0) {
    let premium = thisPricing.reiki.premium,
      optional = 0;

      if (values.reiki.consultingCharge === "yes") {
        optional += thisPricing.reiki.consulting;
        thisPricing.optionalCoveragesCharged["consulting"] = true;
      }
  
      //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;
      totals.reiki.premium = premium;
      totals.reiki.optional= optional;
  }
    //Herbalist
    if (values.applicantInformation.modalities.indexOf("Herbalist") >= 0) {
      let premium  = thisPricing.herbalistPrices[values.herbalist.selectedOption] || 0,
      optional = 0;
  
      totals.herbalist.premium = premium;
      totals.herbalist.optional = optional;
    }
    //Registered Dietitian
    if (values.applicantInformation.modalities.indexOf("Registered Dietitian") >= 0) {
      let premium  = thisPricing.registeredDietitianPrices[values.registeredDietitian.selectedOption] || 0,
      optional = 0;
  
      totals.registeredDietitian.premium = premium;
      totals.registeredDietitian.optional = optional;
    }

  totals.tax = thisPricing.provincialTax[values.applicantInformation.province] || 1;

  totals = premiumOverride(values, totals)

  return Object.values(totals).reduce(
    (acc, curr) => Math.max(acc, curr.premium || 0),
    0
  );
};

/** - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 * 
 * handleOptions
 * 
 */
const handleOptions = (values) => {
  let totals = {
    RMT: {
      premium: 0,
      optional: 0,
    },
    yoga: {
      premium: 0,
      optional: 0,
    },
    aromatherapy: {
      premium: 0,
      optional: 0,
    },
    NC: {
      premium: 0,
      optional: 0,
    },
    pilates: {
      premium: 0,
      optional: 0,
    },
    psychologist: {
      premium: 0,
      optional: 0,
    },
    personalTrainer: {
      premium: 0,
      optional: 0,
    },
    reflexology: {
      premium: 0,
      optional: 0,
    },
    osteopathy: {
      premium: 0,
      optional: 0,
    },
    kinesiology: {
      premium: 0,
      optional: 0,
    },
    physiotherapy: {
      premium: 0,
      optional: 0,
    },
    fitnessInstructor: {
      premium: 0,
      optional: 0,
    },
    catA: {
      premium: 0,
      optional: 0,
    },
    catB: {
      premium: 0,
      optional: 0,
    },
    catC: {
      premium: 0,
      optional: 0,
    },
    acupuncture: {
      premium: 0,
      optional: 0,
    },
    esthetics: {
      premium: 0,
      optional: 0,
    },
    lifeCoach:{
        premium:0,
        optional:0
    },
    sleepConsultant:{
        premium:0,
        optional:0
    },
    reiki:{
        premium:0,
        optional:0
    },
    privacyBreach:{
      premium: 0,
      optional: 0,
    },
    tax: 1,
  };
  let price = 0;

  // determine if the pricing is for new business or old, and what the effective date is
  let effectiveDate = new Date().setHours(24, 0, 0, 0); // next midnight
  if (values.applicantInformation.newPolicyyes_no === "yes" && values.applicantInformation.expiring_policydifferentDate !== "") {
    effectiveDate = new Date(values.applicantInformation.expiring_policydifferentDate).setHours(24, 0, 0, 0);
  }
  let thisPricing;

  // knowing the effective date, go backwards through the pricing array to find the pricing that matches
  // pricing in array is epoch time, so multiply by 1000 to get javascript version
  for (let i = pricing.length - 1; i >= 0; i--) {
    if (values.applicantInformation.newPolicyyes_no === "yes") {
      if (effectiveDate > pricing[i].effective.new * 1000) {
        thisPricing = pricing[i];
        break;
      }
    } else {
      if (effectiveDate > pricing[i].effective.renewal * 1000) {
        thisPricing = pricing[i];
        break;
      }
    }
  }

  // RMT (Ontario)
  if (
    values.applicantInformation.modalities.indexOf("RMT") >= 0 &&
    values.RMT.goodStanding !== "no" &&
    values.applicantInformation.province === "Ontario"
  ) {
    let premium = thisPricing.vals[values.RMT.selection] || 0,
      optional = 0;
    if (
      values.RMT.extraModalities === "yes" &&
      (values.RMT.selection.indexOf("annualRMT") >= 0 ||
        values.RMT.selection.indexOf("newGradRMT") >= 0)
    ) {
      optional += 100;
    } else if (
      values.RMT.extraModalities === "yes" &&
      values.RMT.selection.indexOf("sixMonth") >= 0
    ) {
      optional += 50;
    }

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    if (values.RMT.goodStandingapplied === "no") {
      totals.RMT.premium = 0;
      totals.RMT.optional = 0;
    } else {
      totals.RMT.premium = premium;
      totals.RMT.optional = optional;
    }
    thisPricing.yogaPrices.premium = 0;
    thisPricing.aromatherapyPrices.premium = 0;
    thisPricing.aromatherapyPrices.CFA = 0;
    thisPricing.aromatherapyPrices.BCAPA = 0;
    thisPricing.ncPrices.premium = 0;
    thisPricing.personalTrainerPrices.standard = 0;
    thisPricing.personalTrainerPrices.extended = 0;
    thisPricing.reflexologyPrices.premium = 0;
    thisPricing.osteopathyPrices.fullPremium = 0;
    thisPricing.osteopathyPrices.studentPremium = 0;
    thisPricing.kinesiologyPrices.premium = 0;
    thisPricing.physiotherapyPrices.premium = 0;
    thisPricing.fitnessInstructorPrices.premium = 0;
  }

  // RMT (British Columbia)
  if (
    values.applicantInformation.modalities.indexOf("RMT") >= 0 &&
    values.RMT.goodStanding !== "no" &&
    values.applicantInformation.province === "British Columbia"
  ) {
    let premium = thisPricing.rmtBC[values.RMT.selection] || 0,
      optional = 0;
    if (
      values.RMT.extraModalities === "yes" &&
      values.RMT.selection.indexOf("annual") >= 0
    ) {
      optional += 100;
    } //handle six month pricing for BC
    else if (
        values.RMT.extraModalities === "yes" &&
        values.RMT.selection.indexOf("sixMonth") >= 0
      ) {
        optional += 50;
      }

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    if (values.RMT.goodStandingapplied === "no") {
      totals.RMT.premium = 0;
      totals.RMT.optional = 0;
    } else {
      totals.RMT.premium = premium;
      totals.RMT.optional = optional;
    }
    thisPricing.yogaPrices.premium = 0;
    thisPricing.aromatherapyPrices.premium = 0;
    thisPricing.aromatherapyPrices.CFA = 0;
    thisPricing.aromatherapyPrices.BCAPA = 0;
    thisPricing.ncPrices.premium = 0;
    thisPricing.personalTrainerPrices.standard = 0;
    thisPricing.personalTrainerPrices.extended = 0;
    thisPricing.reflexologyPrices.premium = 0;
    thisPricing.osteopathyPrices.fullPremium = 0;
    thisPricing.osteopathyPrices.studentPremium = 0;
    thisPricing.osteopathyPrices.NMOS = 0;
    thisPricing.kinesiologyPrices.premium = 0;
    thisPricing.physiotherapyPrices.premium = 0;
    thisPricing.fitnessInstructorPrices.premium = 0;
  }
  // RMT Saskatchewan
  if (
    values.applicantInformation.modalities.indexOf("RMT") >= 0 &&
    values.applicantInformation.province === "Saskatchewan"
  ){
    let premium = thisPricing.rmtSK[values.RMT.selection] || 0,
      optional = 0;
      if(values.RMT.practiceOsteopathyyesNo === "yes"){
        optional += 300;
      }
    totals.RMT.premium = premium;
    totals.RMT.optional = optional;
  }
  // Physiotherapy
  if (values.applicantInformation.modalities.indexOf("Physiotherapy") >= 0) {
    let premium = 0,
      optional = 0;
    premium = thisPricing.physiotherapyPrices[values.physiotherapy.selection] || 0;

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    totals.physiotherapy.premium = premium;
    totals.physiotherapy.optional = optional;

    thisPricing.yogaPrices.premium = 0;
    thisPricing.aromatherapyPrices.premium = 0;
    thisPricing.aromatherapyPrices.CFA = 0;
    thisPricing.aromatherapyPrices.BCAPA = 0;
    thisPricing.ncPrices.premium = 0;
    thisPricing.personalTrainerPrices.standard = 0;
    thisPricing.personalTrainerPrices.extended = 0;
    thisPricing.reflexologyPrices.premium = 0;
    thisPricing.osteopathyPrices.fullPremium = 0;
    thisPricing.osteopathyPrices.studentPremium = 0;
    thisPricing.kinesiologyPrices.premium = 0;
    thisPricing.fitnessInstructorPrices.premium = 0;
  }

  // Yoga
  if (values.applicantInformation.modalities.indexOf("Yoga") >= 0) {
    let premium =
        values.yoga.modo === "yes" ||
        values.yoga.yyoga === "yes" ||
        values.yoga.power === "yes"
        ? thisPricing.yogaPrices.listedStudio
        : thisPricing.yogaPrices.premium,
      optional = 0;
    if (
      values.yoga.modo === "yes" ||
      values.yoga.yyoga === "yes" ||
      values.yoga.power === "yes"
    ) {
      thisPricing.ncPrices.premium = 0;
      thisPricing.ncPrices.CANNP = 0;
      thisPricing.ncPrices.CSNNAA = 0;
      //thisPricing.fitnessInstructorPrices.premium = 0; since we need to consider the one with higher premoum IMS-1885

      thisPricing.personalTrainerPrices.standard = 0;
      thisPricing.personalTrainerPrices.extended = 0;
      thisPricing.catCPrices["Crossfit trainer"] = 0;

      if (
        values.applicantInformation.modalities.indexOf("NC") >= 0 ||
        values.applicantInformation.modalities.indexOf("Fitness Instructor") >=
          0 ||
        values.personalTrainer.selectedOption === "standard"
      ) {
        premium += 40;
        //find the modality with a higher premium
        thisPricing.fitnessInstructorPrices.premium > premium ?
            premium=thisPricing.fitnessInstructorPrices.premium :
            premium=premium
      }

      if (
        values.personalTrainer.selectedOption === "extended" ||
        values.applicantInformation.modalities.indexOf("Crossfit trainer") >= 0
      ) {
        premium += 60;
      }
    }

    if (values.yoga.paddleboardaddCoverage === "yes") {
      optional += thisPricing.yogaPrices.paddleboard;
    }

    if (values.yoga.therapyaddCoverage === "yes") {
      optional += thisPricing.yogaPrices.therapy;
    }
    if (values.yoga.ebookaddCoverage === "yes") {
      optional += thisPricing.yogaPrices.ebook;
    }
    if (values.yoga.consultingaddCoverage === "yes" || values.yoga.consultingCharge==="yes") {
      optional += thisPricing.yogaPrices.consulting;
      thisPricing.optionalCoveragesCharged["consulting"] = true;
    }

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    if (values.yoga.suspension === "yes") {
      totals.yoga.premium = 0;
      totals.yoga.optional = 0;
    } else {
      totals.yoga.premium = premium;
      totals.yoga.optional = optional;
    }
  }

  // Aromatherapy
  if (values.applicantInformation.modalities.indexOf("Aromatherapy") >= 0) {
    let premium = thisPricing.aromatherapyPrices.premium,
      optional = 0;

    if (values.aromatherapy.CFA === "yes") {
      premium = thisPricing.aromatherapyPrices.CFA;
    }

    if (values.aromatherapy.BCAPA === "yes") {
      premium = thisPricing.aromatherapyPrices.BCAPA;
    }

    if (values.aromatherapy.consultingaddCoverage === "yes" || values.aromatherapy.consultingCharge==="yes") {
      optional += thisPricing.aromatherapyPrices.consulting;
      thisPricing.optionalCoveragesCharged["consulting"] = true;
    }
    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    totals.aromatherapy.premium = premium;
    totals.aromatherapy.optional = optional;
  }

  // Nutrionist
  if (values.applicantInformation.modalities.indexOf("NC") >= 0) {
    let premium = thisPricing.ncPrices.premium,
      optional = 0;

    // Charge them 0 for BIE if they practice NC. They get charged only if the want it as an optional coverage.
    // thisPricing.catCPrices["Bioenergetics Intolerance Elimination"] = 0;

    /* Association pricing */
    if (values.NC.CANNP === "yes") {
      premium = thisPricing.ncPrices.CANNP;
    } else if (values.NC.CSNNAA === "yes") {
      premium = thisPricing.ncPrices.CSNNAA;
    }

    /* Compute optional coverage */

    if (values.NC.CANNP === "yes" || values.NC.CSNNAA === "yes") {
      // alert("yes");
      thisPricing.ncPrices.cooking = 50;
    }
    if (values.NC.canceraddCoverage === "yes") {
      optional += thisPricing.ncPrices.cancer;
    }
    if (values.NC.bioenergeticsaddCoverage === "yes") {
      thisPricing.catCPrices["Bioenergetics Intolerance Elimination"] = 0;
      optional += thisPricing.ncPrices.bioenergetics;
    }
    if (values.NC.cookingaddCoverage === "yes") {
      optional += thisPricing.ncPrices.cooking;
    }
    if (values.NC.consultingaddCoverage === "yes" || values.NC.consultingCharge==="yes" ) {
      optional += thisPricing.ncPrices.webconsulting;
      thisPricing.optionalCoveragesCharged["consulting"] = true;
    }
    if (values.NC.microscopyaddCoverage === "yes") {
      optional += thisPricing.ncPrices.microscopy;
    }
    if (values.NC.ebookaddCoverage === "yes") {
      optional += thisPricing.ncPrices.ebook;
    }
    if(values.NC.fitnessInstructorAddCoverage === "yes"){
      optional += thisPricing.ncPrices.fitnessInstructorCoverage;  
    }
    if(values.NC.personalTrainerAddCoverage === "yes"){
      optional += values.NC.personalTrainerAddCoverageOption === "extended"?
      thisPricing.ncPrices.personalTrainerCoverageExtended : thisPricing.ncPrices.personalTrainerCoverageStandard;
    }

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    totals.NC.premium = premium;
    totals.NC.optional = optional;
  }

  // Personal Trainer
  if (values.applicantInformation.modalities.indexOf("Personal Trainer") >= 0) {
    let premium = 0,
      optional = 0;
    /* Compute premium */
    if (values.personalTrainer.selectedOption === "standard") {
      premium += thisPricing.personalTrainerPrices.standard;
    }
    if (values.personalTrainer.selectedOption === "extended") {
      premium += thisPricing.personalTrainerPrices.extended;
    }

    /* Compute optional coverage */
    if (values.personalTrainer.consultingaddCoverage === "yes" || values.personalTrainer.consultingCharge==="yes") {
      optional += thisPricing.personalTrainerPrices.webconsulting;
      thisPricing.optionalCoveragesCharged["consulting"] = true;
    }

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    /* handle associations */
    totals.personalTrainer.premium = premium;
    totals.personalTrainer.optional = optional;
  }

  // Reflexology
  if (values.applicantInformation.modalities.indexOf("Reflexology") >= 0) {
    let premium = thisPricing.reflexologyPrices.premium,
      optional = 0;

    /* handle associations */
    if (values.reflexology.RAC === "yes") {
      premium = thisPricing.reflexologyPrices.RACPrices[values.reflexology.RACPolicy] || premium;
    }
    
    totals.reflexology.premium = premium;
    totals.reflexology.optional = optional;

    if (values.reflexology.certificationaddCoverage === "yes") {
      optional += thisPricing.reflexologyPrices.certification;
      thisPricing.optionalCoveragesCharged["certification"] = true;
    }

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    totals.reflexology.premium = premium;
    totals.reflexology.optional = optional;
  }

  // Osteopathy
  if (values.applicantInformation.modalities.indexOf("Osteopathy") >= 0) {
    let premium = thisPricing.osteopathyPrices.fullPremium,
      optional = 0;

    // Observe this and removed as of June 2023
    // if (values.osteopathy.associationPicker === "NMOS") {
    //   premium = thisPricing.osteopathyPrices.NMOS;
    // }
    if (values.osteopathy.associationPicker.includes("others")) {
      premium = thisPricing.osteopathyPrices.Others;
    }

    if (
      values.osteopathy.student === "yes" &&
      values.osteopathy.completedStudentyesNo === "no"
    ) {
      premium = thisPricing.osteopathyPrices.studentPremium;
    }

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    totals.osteopathy.premium = premium;
    totals.osteopathy.optional = optional;
  }

  // Kinesiology
  if (values.applicantInformation.modalities.indexOf("Kinesiology") >= 0) {
    let premium = thisPricing.kinesiologyPrices.premium,
      optional = 0;

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    totals.kinesiology.premium = premium;
    totals.kinesiology.optional = optional;
  }

  // Fitness Instructor
  if (
    values.applicantInformation.modalities.indexOf("Fitness Instructor") >= 0
  ) {
    let premium = thisPricing.fitnessInstructorPrices.premium,
      optional = 0;

    if (values.fitnessInstructor.paddleboardaddCoverage === "yes") {
      optional += thisPricing.fitnessInstructorPrices.paddleboard;
    }
    if (values.fitnessInstructor.consultingaddCoverage === "yes" || values.fitnessInstructor.consultingCharge==="yes") {
      optional += thisPricing.fitnessInstructorPrices.webconsulting;
      thisPricing.optionalCoveragesCharged["consulting"] = true;
    }

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    totals.fitnessInstructor.premium = premium;
    totals.fitnessInstructor.optional = optional;
  }

  // Pilates
  if (values.applicantInformation.modalities.indexOf("Pilates") >= 0) {
    let premium = thisPricing.pilatesPrices.premium,
      optional = 0;

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;
    //Web based consulting
    if(values.pilates.consultingaddCoverage==="yes" || values.pilates.consultingCharge==="yes") optional+= thisPricing.pilatesPrices.webconsulting;

    totals.pilates.premium = premium;
    totals.pilates.optional = optional;
  }

  if (
    modalitiesCatA.some((item) =>
      values.applicantInformation.modalities.includes(item)
    )
  ) {
    totals.catA.premium = thisPricing.catAPrices.premium;
    //totals.catA.optional = (values.privacyBreach.yesno === "yes") ? thisPricing.privacyBreach.premium : 0;
  }

  if (
    modalitiesCatB.some((item) =>
      values.applicantInformation.modalities.includes(item)
    )
  ) {
    totals.catB.premium = thisPricing.catBPrices.premium;
    //totals.catB.optional = (values.privacyBreach.yesno === "yes") ? thisPricing.privacyBreach.premium : 0;
  }

  if (
    modalitiesCatC.some((item) =>
      values.applicantInformation.modalities.includes(item)
    )
  ) {
    values.applicantInformation.modalities.forEach((item) => {
      if (modalitiesCatC.indexOf(item) >= 0) {
        totals.catC.premium = Math.max(totals.catC.premium, thisPricing.catCPrices[item]);
      }
    });
    //totals.catC.optional = (values.privacyBreach.yesno === "yes") ? thisPricing.privacyBreach.premium : 0;
  }

  // Psychologist
  if (values.applicantInformation.modalities.indexOf("Psychologist") >= 0) {
    let premium = thisPricing.psychologistPrices[values.psychologist.selectedOption] || 0,
      optional = 0;
    if (values.psychologist.gcl === "yes") {
      optional = thisPricing.psychologistPrices.optionalPremium;
    }

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    totals.psychologist.premium = premium;
    totals.psychologist.optional = optional;
  }

  // Acupuncture
  if (values.applicantInformation.modalities.indexOf("Acupuncture/TCM") >= 0) {
    let premium = 0, optional = 0;
    if (values.acupuncture.associationMember === "yes") {
      premium = thisPricing.acupuncturePrices.association[values.acupuncture.selectedOption] || 0;
    } else if (values.applicantInformation.province === "Ontario") {
      premium = thisPricing.acupuncturePrices.ontario[values.acupuncture.selectedOption] || 0;
    } else {
      premium = thisPricing.acupuncturePrices.other[values.acupuncture.selectedOption] || 0;
    }

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;

    totals.acupuncture.premium = premium;
    totals.acupuncture.optional = optional;
  }

  // Esthetics
  if (values.applicantInformation.modalities.indexOf("Esthetics") >= 0) {
    let premium = thisPricing.estheticsPrices.premium,
      optional = 0;

    //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;
    if(values.esthetics.microneedlingYesOrNo === "yes" && values.esthetics.microneedlingAddnPremiumYesOrNo === "yes"){
      optional += thisPricing.estheticsPrices.microneedlingPremium;
    }
    if(values.esthetics.microbladingYesOrNo === "yes" && values.esthetics.microbladingAddnPremiumYesOrNo === "yes"){
      optional += thisPricing.estheticsPrices.microbladingPremiun;
    }

    totals.esthetics.premium = premium;
    totals.esthetics.optional = optional;
  }
  if (values.applicantInformation.modalities.indexOf("Life Coach") >= 0) {
    let premium = thisPricing.lifeCoach.premium,
      optional = 0;

      if (values.lifeCoach.consultingCharge === "yes") {
        optional += thisPricing.lifeCoach.consulting;
        thisPricing.optionalCoveragesCharged["consulting"] = true;
      }
  
      //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;
      totals.lifeCoach.premium = premium;
      totals.lifeCoach.optional= 
      optional;
  }
  if (values.applicantInformation.modalities.indexOf("Sleep Consultant") >= 0) {
    let premium = thisPricing.sleepConsultant.premium,
      optional = 0;

      if (values.sleepConsultant.consultingCharge === "yes") {
        optional += thisPricing.sleepConsultant.consulting;
        thisPricing.optionalCoveragesCharged["consulting"] = true;
      }
  
      //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;
      totals.sleepConsultant.premium = premium;
      totals.sleepConsultant.optional= optional;
  }
  if (values.applicantInformation.modalities.indexOf("Reiki") >= 0) {
    let premium = thisPricing.reiki.premium,
      optional = 0;

      if (values.reiki.consultingCharge === "yes") {
        optional += thisPricing.reiki.consulting;
        thisPricing.optionalCoveragesCharged["consulting"] = true;
      }
  
      //if (values.privacyBreach.yesno === "yes") optional += thisPricing.privacyBreach.premium;
      totals.reiki.premium = premium;
      totals.reiki.optional = optional;
  }
    // add-on for all modalities: Privacy Breach/Cyber Security coverage except Acupuncture/TCM
    if (values.privacyBreach.yesno === "yes" && values.applicantInformation.modalities.indexOf('Acupuncture/TCM') <=0) {
        if(totals.privacyBreach.optional===0){ // This should add privacy beach only once no matter how many modalilites
            totals.privacyBreach.optional = thisPricing.privacyBreach.premium;
        }
      }

  totals.tax = thisPricing.provincialTax[values.applicantInformation.province] || 1;
  totals = dedupeWebBasedConsulting(values, thisPricing, totals);

  return Object.values(totals).reduce(
    (acc, curr) => acc + curr.optional || acc,
    0
  );
};

// If the applicant has CSNNA or CANNP or Reflexology Canada then they should be given 
// association pricing, irrespective of which other modality is picked
const premiumOverride = (values, totals) =>{
    if (values.NC.CANNP === "yes" || values.NC.CSNNAA === "yes" || values.reflexology.RAC === "yes") {
        const allKeys = Object.keys(totals);
        for (let key of allKeys) {
            if (totals[key].premium > 0 && (key !== 'NC' && key !== 'reflexology')) {
                totals[key].premium = 0;
                totals[key].optional = 0;
            }
        }
      } 
      if ((values.NC.CANNP === "yes" || values.NC.CSNNAA === "yes") && values.reflexology.RAC === "yes"){
        //if NC and reflexology both associaitons are present, use the lower value
        const ncPremium = totals['NC'].premium
        const refPremium = totals['reflexology'].premium
        if(ncPremium > refPremium){
            totals['NC'].premium = 0;
            totals['NC'].optional = 0;
        }else if(refPremium > ncPremium){
            totals['reflexology'].premium = 0;
            totals['reflexology'].optional = 0;
        }
      }
      return totals;
}

const dedupeWebBasedConsulting = (values, thisPricing, totals)=>{
    let count = 0
    // Get all the keys from the object since we cannot use the modalities in applicant information as it refers to the acutal name of the madality
    const allKeys = Object.keys(totals);
    const result = [];
    for (let key of allKeys) {
        if (totals[key].optional > 0) {
        result.push(key);
        }
    }
    // Check for each modality if they have web based consulting said yes to 
    for(let mod of result){
        if(values[mod].consultingaddCoverage === "yes" || values[mod].consultingCharge === "yes"){
            count++;
            if(count>1){
                totals[mod].optional-=thisPricing[mod+'Prices'].webconsulting
            }
        }
    }
    return totals
}

const handleTax = (values) => {
  let provincialTax = {
    Ontario: 1.08,
    Quebec: 1.09,
    Manitoba: 1.07,
    Saskatchewan: 1.06,
    "Newfoundland and Labrador": 1.15,
  };

  return provincialTax[values.applicantInformation.province] || 1;
};

export default handlePricing;
